import { lighten } from "../../../utils/sassFunction";

export const colors = [
  { name: "primary", title: "Primario", value: "#009fe3" },
  { name: "primaryHover", title: "Primario alternativo", value: "#00577d" },
  { name: "warning", title: "Amarillo", value: "#ffb700" },
  { name: "grey", title: "Gris", value: "#6f6f6f" },
  { name: "dark", title: "Secundario", value: "#404040" },
  { name: "info", title: "Info", value: "#336699" },
  { name: "bronce", title: "Bronce", value: "#a38e59" },
  { name: "vibrant", title: "Vivo", value: "#1e73be" },
  { name: "marca", title: "Marca Deportiva", value: "#cf2e2e" },
  { name: "vision-show", title: "Visión Show", value: "#6e2ca0" },
  { name: "salud", title: "Bienestar y salud", value: "#38d627" },
];

export const colorsalert = [
  { name: "light", title: "Default", value: `${lighten("#6f6f6f", 48)}` },
  { name: "success", title: "Suceso", value: "#118f63" },
  { name: "warning", title: "Alerta", value: "#ffb700" },
  { name: "danger", title: "Peligro", value: "#b9000b" },
];
